
.page-size-selector {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex-direction: row;

	&__selector {
		margin: 0 10px;
	}

	&__label {
		font-weight: 600;
	}
}
