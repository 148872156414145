.pagination {
	display: flex;
	list-style-type: none;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding-inline-start: 50px;
}

.page-link {
	position: relative;
	display: block;
	text-decoration: none;
	background-color: #fff;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	padding: 0.375rem 0.75rem;

	&:hover {
		z-index: 2;
		cursor: pointer;
	}

	&:focus {
		z-index: 3;
		outline: 0;
	}
}

.page-item {
	margin: 0 0.175rem;
	border-bottom: 2px solid transparent;

	&:not(:first-child) .page-link {
		margin-left: -1px;
	}

	&.active {
		z-index: 3;
		border-bottom-color: #0078d4;

		.page-link {
			font-weight: 500;
		}
	}

	&:hover {
		border-bottom-color: #0078d4;
	}

	&.disabled {
		border-color: transparent;
	}

	&.disabled .page-link {
		color: #6c757d;
		pointer-events: none;
		border-color: transparent;
	}
}
