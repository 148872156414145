@font-face {
    font-family: "FabricMDL2Icons-all";
    src: url(assets/fabric-mdl2-external-42cadb6a.woff) format("woff");
}

.task-list-icon {
    font-family: 'FabricMDL2Icons-all';
    font-style: normal;

    &:before {
        content: '\E3B6'
    }
}

.job-list {
	width: 100%;
	max-width: 1550px;

	&__container {
		width: 100%;
		overflow-x: auto;
	}

	&__icon {
		font-family: "FabricMDL2Icons-all";
		font-style: normal;
		color: gray;

		&:before {
			content: "\E562";
		}
	}

	&__active-filter {
		background-color: #00000010;
	}

	&__page-footer {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 24px;
	}
}

.message-bar {
	position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}
